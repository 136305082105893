import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import MainHeader from './screens/MainHeader';
import Home from './screens/Home';
import AboutUs from './screens/AboutUs';
import Error from './screens/Error';
import ForPatientsCaregivers from './screens/ForPatientsCaregivers';
import ForHealthcareServiceProviders from './screens/ForHealthcareServiceProviders';
import ForHealthInsurarers from './screens/ForHealthInsurarers';
import ProductsFeatures from './screens/ProductsFeatures';
import Careers from './screens/Careers';
import ContactUs from './screens/ContactUs';
import EULA from './screens/Policy/EULA';
import PolicySecurity from './screens/Policy/PrivacySecurity';
import TermsCondition from './screens/Policy/TermsConditions';
import Refunds from './screens/Policy/Refunds';
import Shipping from './screens/Policy/Shipping';
import DataPolicy from './screens/Policy/DataPolicy';
import LegalDisclaimer from './screens/Policy/LegalDisclaimer';
import OurProducts from './screens/OurProducts';
import BlogPost from './screens/BlogPost';
import Sunita from './screens/Sunita';
import PartnerPortal from './screens/PartnerPortal';
import Abha from './screens/Abha';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainHeader />}>

          <Route index element={<Home />}></Route>
          <Route path='ForPatientsCaregivers' element={<ForPatientsCaregivers />}></Route>
          <Route path='ForHealthcareServiceProviders' element={<ForHealthcareServiceProviders />}></Route>
          <Route path='ForHealthInsurarers' element={<ForHealthInsurarers />}></Route>
          <Route path='ourProducts' element={<OurProducts />}></Route>

          <Route path='aboutus' element={<AboutUs />}></Route>
          <Route path='careers' element={<Careers />}></Route>
          <Route path='contactus' element={<ContactUs />}></Route>
          <Route path='blogPost' element={<BlogPost />}></Route>
          <Route path='Sunita' element={<Sunita />}></Route>
          <Route path='partnerPortal' element={<PartnerPortal />}></Route>
          <Route path='abha' element={<Abha />}></Route>

          <Route path='end-user-license-agreement-eula' element={<EULA />}></Route>
          <Route path='privacy-and-security-policy' element={<PolicySecurity />}></Route>
          <Route path='terms-conditions' element={<TermsCondition />}></Route>
          <Route path='refund-cancellation-policy' element={<Refunds />}></Route>
          <Route path='shipping-policy' element={<Shipping />}></Route>
          <Route path='data-privacy-policy' element={<DataPolicy />}></Route>
          <Route path='legal-disclaimer' element={<LegalDisclaimer />}></Route>
        </Route>
        <Route path="*" element={<Error />} status={404} />
      </Routes>
    </div>
  );
}

export default App;
