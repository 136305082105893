import React from 'react'
import LandingTile from '../components/LandingTile'
import bgImage from '../assets/ForHealthcareServiceProviders/bgImage.png'
import Circle1 from '../assets/ForHealthcareServiceProviders/Circle1.png'
import Circle2 from '../assets/ForHealthcareServiceProviders/Circle2.png'
import Circle3 from '../assets/ForHealthcareServiceProviders/Circle3.png'
import ForPharmacies from '../assets/ForHealthcareServiceProviders/ForPharmacies.png'
import ForHospitalsandClinics from '../assets/ForHealthcareServiceProviders/ForHospitalsandClinics.png'
import ForPathologicalLabs from '../assets/ForHealthcareServiceProviders/ForPathologicalLabs.png'
import Icon1 from '../assets/ForHealthcareServiceProviders/Icon1.png'
import Icon2 from '../assets/ForHealthcareServiceProviders/Icon2.png'
import Icon3 from '../assets/ForHealthcareServiceProviders/Icon3.png'
import Icon4 from '../assets/ForHealthcareServiceProviders/Icon4.png'
import Icon5 from '../assets/ForHealthcareServiceProviders/Icon5.png'
import Icon6 from '../assets/ForHealthcareServiceProviders/Icon6.png'
import Icon7 from '../assets/ForHealthcareServiceProviders/Icon7.png'
import Icon8 from '../assets/ForHealthcareServiceProviders/Icon8.png'
import Icon9 from '../assets/ForHealthcareServiceProviders/Icon9.png'
import Icon10 from '../assets/ForHealthcareServiceProviders/Icon10.png'
import Icon11 from '../assets/ForHealthcareServiceProviders/Icon11.png'
import Icon12 from '../assets/ForHealthcareServiceProviders/Icon12.png'
import TextComponent from '../components/TextComponent'
import Footer from '../components/Footer'

const ForHealthcareServiceProviders = () => {
  return (
    <div className="bg-white min-h-screen scroll-smooth">
      <LandingTile
        bgImage={bgImage}
        headerText={'Provide your services to users directly'}
        headerColor={'#000000'}
        subparagraphText={'AllesHealth Is A Perfect Solution As A Digital Companion For Your Patients That Can Help Increase Efficiency Of Your Clinical Staff And Also Improve The Way You Engage With Your Patients.'}
        subColor={'#2A79BC'}
        bottomColor={'#DDF2C1'} />

<div className="flex flex-col-reverse lg:flex-row justify-center align-center w-[80vw] h-auto mx-[10vw] my-[2vh] lg:my-[10vh] gap-10">
        {/* TEXT on left */}
        <div className="flex flex-col justify-center align-center w-full lg:w-1/2">
          <TextComponent DATA={DATA} />
        </div>

        {/* IMAGES on right */}
        <div className="w-full lg:w-1/2 pl-13 h-auto flex flex-col justify-center align-center items-center content-center relative lg:gap-[0vh]">
          <div className="homeBg w-full lg:ml-[15vh]">
            <img src={Circle1} alt="Image1" className="w-3/5 lg:w-[21vw] lg:h-[21vw] object-cover bg-none rounded-full" />
          </div>
          <div className="homeBg w-full flex justify-end absolute lg:right-[-5vw] xxxs:max-lg:top-[30vw] top-[50%]">
            <img src={Circle3} alt="Image3" className="w-1/2 lg:w-[25vw] lg:h-[25vw] object-contain rounded-full" />
          </div>
          <div className="homeBg mt-[5vh] lg:mt-[1vh] w-full">
            <img src={Circle2} alt="Image2" className="w-3/5 ml-[1vw] lg:w-[15vw] lg:h-[15vw] object-contain rounded-full" />
          </div>
        </div>
      </div>

      <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

      {/* FOOTER */}
      <Footer />
    </div>
  )
}

export default ForHealthcareServiceProviders;

const DATA = [
  {
    id: 1,
    Icon: ForPharmacies,
    header: 'For Pharmacies',
    sub: 'AllesHealth Is A Perfect Solution As A Digital Companion For Your Patients That Can Help Increase your Average Ticket value And Also Improve The Way You Engage With Your Patients.',
    data: [
      {
        id: 1,
        icon: Icon1,
        text: 'Get more Direct Customers without referrals',
      },
      {
        id: 2,
        icon: Icon2,
        text: 'Spend less and get Word of Mouth Recommendations',
      },
      {
        id: 3,
        icon: Icon3,
        text: 'Grow your Business Organically',
      },
      {
        id: 4,
        icon: Icon4,
        text: 'Create a Patient trust around your Business',
      },
    ],
  },
  {
    id: 2,
    Icon: ForHospitalsandClinics,
    header: 'For Hospitals and Clinics',
    sub: 'AllesHealth Is A Perfect Solution As A Digital Companion For Your Patients That Can Help Increase Efficiency Of Your Clinical Staff And Also Improve The Way You Engage With Your Patients.',
    data: [
      {
        id: 1,
        icon: Icon5,
        text: 'Digital Companion For Patients',
      },
      {
        id: 2,
        icon: Icon6,
        text: 'Real data insights for ease of access',
      },
      {
        id: 3,
        icon: Icon7,
        text: 'Patient Centric Generated Clinical Insights',
      },
      {
        id: 4,
        icon: Icon8,
        text: 'Real World Data To Help In Early Diagnosis',
      },
    ],
  },
  {
    id: 3,
    Icon: ForPathologicalLabs,
    header: 'For Pathological Labs',
    sub: 'AllesHealth Is A True Partner For Health Pathological Labs. By Providing Customisable, Easy to use Platform, AllesHealth Helps Labs Achieve Higher Customer Reach And Top-Line Growth with less marketing cost.',
    data: [
      {
        id: 1,
        icon: Icon9,
        text: 'Get more walk-in customers directly',
      },
      {
        id: 2,
        icon: Icon10,
        text: 'Increase your customer reach',
      },
      {
        id: 3,
        icon: Icon11,
        text: 'Save on Referral & hidden  Fees',
      },
      {
        id: 4,
        icon: Icon12,
        text: 'Save expenses on marketing',
      },
    ],
  },
]