import React from 'react'
import bgImage from '../assets/OurProducts/bgImage.jpg'
import App from '../assets/OurProducts/App.png'
import kit from '../assets/OurProducts/kit.png'
import clinic from '../assets/OurProducts/clinic.png'
import Tile2 from '../assets/OurProducts/Tile2New.png'
import ConnectTile from '../assets/OurProducts/ConnectTile.png'
import ProgramSunita from '../assets/OurProducts/ProgramSunita.png'
import bgFlowers from '../assets/OurProducts/bgFlowers.png'
import Icon1 from '../assets/ProductsFeatures/Icon1.png'
import Icon2 from '../assets/ProductsFeatures/Icon2.png'
import Icon3 from '../assets/ProductsFeatures/Icon3.png'
import bgPortal from '../assets/OurProducts/bgPortal.png'
import Portal from '../assets/OurProducts/Portal.png'
import { MdOutlineArrowOutward } from "react-icons/md";
import Patient from '../assets/OurProducts/Patient.png';
import Carers from '../assets/OurProducts/Carers.png';
import Hospital from '../assets/OurProducts/Hospital.png';
import Labs from '../assets/OurProducts/Labs.png';
import Health from '../assets/OurProducts/Health.png';
import AppStore from '../assets/OurProducts/AppStore.png';
import PlayStore from '../assets/OurProducts/PlayStore.png';
import loopIcon from '../assets/Home/loopIcon.png';
import linkedinIcon from '../assets/Home/linkedinIcon.png';
import instaIcon from '../assets/Home/instaIcon.png';
import telegramIcon from '../assets/Home/telegramIcon.png';
import Footer from '../components/Footer'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom';

const OurProducts = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white min-h-screen scroll-smooth">
            {/* BG Image */}
            <div className="homeBg w-[90vw] my-[5vh] mx-[5vw] h-auto">
                <LazyLoadImage
                    src={bgImage}
                    effect="blur"
                    width={'90vw'}
                    placeholderSrc={bgImage}
                    alt="Background Image"
                    className="w-full h-auto object-cover" />
            </div>

            {/* TILE1 */}
            <div
                style={{ background: 'linear-gradient(90deg, rgba(42, 184, 229, 0.54) 40%, rgba(98, 181, 207, 0.43) 70%)' }}
                className="w-[90vw] mx-[5vw] rounded-xl shadow-xl flex flex-col lg:flex-row lg:justify-around items-start lg:items-center py-2 lg:py-0 px-5">
                {/* <img src={Tile1} alt="Tile" className="w-full h-auto object-cover" /> */}
                <div className="flex justify-center items-center p-2">
                    <h1 className="bg-[#FACFD9] rounded-l-lg text-lg font-regular text-[#000000] my-1 py-2 text-center px-5">Powered By :</h1>
                </div>
                <div className="flex flex-row justify-center gap-4 items-center p-2">
                    <img src={Icon1} alt="Icon1" className="w-10 h-auto object-cover" />
                    <h1 className="text-lg font-regular text-[#000000] my-1 text-center">Blockchain</h1>
                </div>
                <div className="flex flex-row justify-center gap-4 items-center p-2">
                    <img src={Icon2} alt="Icon2" className="w-10 h-auto object-cover" />
                    <h1 className="text-lg font-regular text-[#000000] my-1 text-center">Big Data Analytics</h1>
                </div>
                <div className="flex flex-row justify-center gap-4 items-center p-2">
                    <img src={Icon3} alt="Icon3" className="w-10 h-auto object-cover" />
                    <h1 className="text-lg font-regular text-[#000000] my-1 text-center">Artificial Inteligence</h1>
                </div>
            </div>

            {/* Alleshealth Connect */}
            <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mt-[8vh] mb-10">AllesHealth Connect</h1>
            <div className="w-[80vw] mx-[10vw] px-5 flex flex-col justify-center items-center">
                <div className="w-[80vw] mx-[10vw] flex flex-row justify-center items-center gap-5">
                    <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                        <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                            <img src={kit} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                        </div>
                        <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Clinics</h1>
                    </div>
                    <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                        <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                            <img src={clinic} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                        </div>
                        <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Hospitals</h1>
                    </div>
                </div>
            </div>
            <div className="w-[90vw] h-auto">
                <img src={ConnectTile} alt="Tile" className="w-[100vw] h-auto object-cover" />
            </div>

            {/* Alleshealth APP */}
            <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mt-[8vh] mb-10">AllesHealth Patients App</h1>
            <div style={{ backgroundColor: 'rgba(217, 217, 217, 0.13)' }} className="w-[80vw] mx-[10vw] py-[8vh] px-5 flex flex-col justify-center items-center shadow-xl rounded-xl">
                <div className="w-[80vw] mx-[10vw]  mb-10 flex flex-row justify-center items-center gap-5">
                    <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                        <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                            <img src={Patient} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                        </div>
                        <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Patients</h1>
                    </div>
                    <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                        <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                            <img src={Carers} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                        </div>
                        <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Carers</h1>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <img src={App} alt="App" className="w-[90vw] h-auto object-contain" />
                </div>
                <div className="md:w-1/2 mt-10 flex flex-row justify-center items-center gap-10">
                    <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="flex justify-center items-center">
                        <img src={AppStore} alt="AppStore" className="w-[90vw] h-auto object-contain" />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="flex justify-center items-center">
                        <img src={PlayStore} alt="PlayStore" className="w-[90vw] h-auto object-contain" />
                    </a>
                </div>
            </div>

            {/* Partner Portal */}
            <div className="mt-[10vh] w-[100vw] min-h-screen flex flex-col justify-center items-center relative">
                {/* Background Image */}
                <div className="w-full flex justify-center items-center">
                    <img src={bgPortal} alt="background" className="w-full h-[120vh] lg:h-[90vw] object-cover" />
                </div>

                <div className='absolute top-15 pb-8'>
                    {/* Header */}
                    <h1 className="text-4xl lg:text-6xl font-semibold text-[#42B8F7] text-center mb-5">Partner Portal</h1>

                    <div className="w-[80vw] mx-[10vw] mt-[10vh] mb-10 flex flex-row justify-center items-center gap-5">
                        <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                            <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                                <img src={Health} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                            </div>
                            <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Health Insurers</h1>
                        </div>
                        <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                            <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                                <img src={Hospital} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                            </div>
                            <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Hospitals/ Clinics</h1>
                        </div>
                        <div className="xxxs:max-md:w-full flex flex-col justify-center items-center">
                            <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.09)' }} className="flex justify-center items-center rounded-full w-[20vw] h-[20vw] md:w-[8vw] md:h-[8vw]">
                                <img src={Labs} alt="Icon" className="w-[12vw] md:w-[5vw] h-auto object-contain" />
                            </div>
                            <h1 className="w-[20vw] md:w-[8vw] px-1 text-md md:text-[1.2vw] font-regular text-[#3CA5DC] text-center">For Laboratories</h1>
                        </div>
                    </div>

                    {/* Portal Image */}
                    <div className="w-[100vw] py-[8vh] px-5 flex justify-center items-center">
                        <img src={Portal} alt="Portal" className="w-[90vw] h-auto object-contain" />
                    </div>

                    {/* Icons */}
                    <div className="mt-10 flex flex-col lg:flex-row justify-center items-center gap-10">
                        <a href='https://partner.alleshealth.com/' target='_blank' className="bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] items-center w-[60vw] lg:w-[20vw] py-5 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                            Become a Partner <MdOutlineArrowOutward />
                        </a>
                        <div onClick={() => { navigate("/contactus") }} className="bg-[#EEC300] flex justify-center font-bold text-[#000000] items-center w-[60vw] lg:w-[20vw] py-5 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                            Learn More <MdOutlineArrowOutward />
                        </div>
                    </div>
                </div>
            </div>

            {/* Tile 2 */}
            <div className="relative mt-10 mb-[10vh] w-[86vw] lg:w-[90vw] h-full mx-[5vw] cursor-pointer">
                <img src={Tile2} alt="Tile" className="w-full h-auto object-cover" />
                <a href='https://partner.alleshealth.com/' target='_blank' className="absolute top-0 left-0 w-1/2 h-[19vw]"></a>
                <div onClick={() => { navigate("/sunita") }} className="absolute bottom-0 right-0 w-1/2 h-full"></div>
                <div onClick={() => { navigate("/contactus") }} className="absolute bottom-0 left-0 w-1/2 h-[27%]"></div>
            </div>

            {/* TILE 2 */}
            {/* <div style={{ overflow: 'hidden' }} className="relative bg-[#FDEDD4] w-[90vw] h-[25vw] mx-[5vw] my-[5vh] flex flex-row justify-center items-center rounded-2xl shadow-xl cursor-pointer">
                <div onClick={() => { navigate("/sunita") }} className="absolute bottom-[-5vh] md:bottom-[-10vh] left-[-7vw] z-0 opacity-50">
                    <img src={bgFlowers} alt="bgFlowers" className="w-[25vw] h-auto object-contain" />
                </div>
                <div onClick={() => { navigate("/sunita") }} className="absolute bottom-[-5vh] md:bottom-[-10vh] right-[-7vw] z-0 opacity-50">
                    <img src={bgFlowers} alt="bgFlowers" className="w-[25vw] h-auto object-contain" />
                </div>

                <div onClick={() => { navigate("/sunita") }} className="flex basis-1/4 flex-col justify-end h-auto items-center gap-5 z-10 cursor-pointer">
                </div>

                <div onClick={() => { navigate("/sunita") }} className="basis-1/2 flex justify-center items-center z-10">
                    <img src={ProgramSunita} alt="ProgramSunita" className="w-full h-auto object-contain" />
                </div>

                <div className="flex basis-1/4 flex-col justify-end h-auto items-center gap-5 z-10 cursor-pointer">
                    <a href='https://partner.alleshealth.com/' target='_blank' className="bg-[#942944] flex justify-center font-bold text-[#FFFFFF] text-[1vw] items-center w-3/4 px-1 lg:px-2 py-1 md:py-4 rounded-l-full rounded-r-full shadow-xl gap-1 lg:gap-2">
                        Become a Partner <MdOutlineArrowOutward />
                    </a>
                    <div onClick={() => { navigate("/contactus") }} className="bg-[#D45573] flex justify-center font-bold text-[#FFFFFF] text-[1vw] items-center w-3/4 px-1 lg:px-2 py-1 md:py-4 rounded-l-full rounded-r-full shadow-xl gap-1 lg:gap-2">
                        Donate for the Effort <MdOutlineArrowOutward />
                    </div>
                </div>
            </div> */}

            {/* <div className="basis-1/4 h-[20vw] flex flex-col justify-end items-start z-10 gap-5">
                    <div className="flex flex-row justify-end items-end gap-5">
                        <img src={linkedinIcon} alt="Linkedin Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                        <img src={loopIcon} alt="Loop Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                    </div>
                    <div className="flex flex-row justify-end items-end gap-5">
                        <img src={instaIcon} alt="Insta Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                        <img src={telegramIcon} alt="Telegram Icon" className="w-[4vw] h-[4vw] md:w-12 w-[4vw] h-[4vw] md:h-12 object-contain" />
                    </div>
                </div> */}


            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    )
}

export default OurProducts
