import React, { useEffect } from 'react'
import HomeBg from '../assets/Abha/bgImage-min.png'
import bg1 from '../assets/Abha/bg1.png'
import bg2 from '../assets/Abha/bg2.png'
import bg3 from '../assets/Abha/bg3.png'
import Image1 from '../assets/Abha/Image1.png'
import Image2 from '../assets/Abha/Image2.png'
import Image3 from '../assets/Abha/Image3.png'
import Image4 from '../assets/Abha/Image4.png'
import Tile from '../assets/Abha/Tile.png'
import Icon1 from '../assets/Abha/Icon1.png'
import Icon2 from '../assets/Abha/Icon2.png'
import Icon3 from '../assets/Abha/Icon3.png'
import Icon4 from '../assets/Abha/Icon4.png'
import Icon5 from '../assets/Abha/Icon5.png'
import Icon6 from '../assets/Abha/Icon6.png'
import AppStore from '../assets/PartnerPortal/AppStore.png';
import PlayStore from '../assets/PartnerPortal/PlayStore.png';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MdOutlineArrowOutward } from "react-icons/md";

const Abha = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white min-h-screen scroll-smooth">

            {/* DESKTOP LANDING TILE */}
            <div className="hidden md:flex w-full h-50vh bg-cover bg-center flex justify-center items-center relative">
                {/* BG Image */}
                <div style={{ overflow: 'hidden' }} className="homeBg w-full h-[110vh]">
                    <LazyLoadImage
                        src={HomeBg}
                        effect="blur"
                        width={'100vw'}
                        // height={'90vh'}
                        placeholderSrc={HomeBg}
                        alt="Background Image"
                        className="w-full h-[110vh] object-cover" />
                </div>

                {/* Image Left Text */}
                <div className="p-6 rounded-md absolute w-[80vw] mx-[10vw] text-center top-12 lg:top-12 xl:top-[20vh] flex flex-col justify-center items-center">
                    <h1 className="text-[4.4vw] font-bold  text-[#FFFFFF]">Ayushman Bharat</h1>
                    <h1 className="text-[4.4vw] font-bold mb-4 text-[#FFFFFF]">Digital Mission (ABDM)</h1>
                    <h1 className="text-[2vw] font-semibold mb-4 text-[#FFFFFF]">Developing the backbone necessary to support the integrated digital health infrastructure for India.</h1>
                    <a href='https://download.alleshealth.com/' target='_blank' className="my-5 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[25vw] lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2  cursor-pointer hover:scale-105 duration-500">
                        Create Your ABHA <MdOutlineArrowOutward />
                    </a>
                    <h1 className="mt-10 text-lg font-semibold mb-4 text-[#FFFFFF]">DOWNLOAD ALLESHEALTH APP</h1>

                    <div className="flex flex-row justify-center items-center gap-10">
                        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="w-[10vw] cursor-pointer">
                            <img src={AppStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="w-[10vw] cursor-pointer">
                            <img src={PlayStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                    </div>
                </div>
            </div>

            {/* MOBILE LANDING TILE */}
            <div className="block md:hidden w-full h-40vh bg-cover bg-center flex flex-col justify-center items-center">
                {/* BG Image */}
                <div className="homeBg w-full">
                    <img src={HomeBg} alt="Home background" className="w-full h-auto object-cover" />
                </div>

                {/* Image Text */}
                <div className="px-[10vw] w-full text-left flex flex-col justify-center items-center">
                    <h1 className="text-4xl text-center leading-[6vh] font-bold mb-4 text-[#3CA5DC] mt-5">Ayushman Bharat Digital Mission (ABDM)</h1>
                    <h1 className="text-4xl text-center font-regular mb-4 text-[#000000] mt-5">Developing the backbone necessary to support the integrated digital health infrastructure for India.</h1>

                    <a href='https://download.alleshealth.com/' target='_blank' className="my-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-[60vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                        Create Your ABHA <MdOutlineArrowOutward />
                    </a>

                    <h1 className="text-2xl text-center font-regular mb-4 text-[#000000] mt-5">
                        DOWNLOAD ALLESHEALTH APP
                    </h1>
                    <div className="mb-10 flex flex-row justify-center items-center gap-10">
                        <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="w-[30vw] cursor-pointer">
                            <img src={AppStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="w-[30vw] cursor-pointer">
                            <img src={PlayStore} alt="Download" className="w-full h-auto object-cover" />
                        </a>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="w-full flex flex-col justify-center items-center">

                {/* About */}
                <div className="relative flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                    {/* Background Image */}
                    <div className="w-full h-auto flex justify-center items-center">
                        <img src={bg1} alt="background" className="w-[100vw] h-[110vh] lg:h-[80vh] object-cover" /></div>

                    <div className="absolute top-15  w-[80vw] mx-[10vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                            <h1 className="text-[4.4vw] font-bold text-left text-[#000000]">About Ayushman Bharat Digital Mission</h1>
                        </div>
                        <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                            <h1 className="text-lg font-regular text-left text-[#000000]">The Ayushman Bharat Digital Mission was introduced via video conference on September 27, 2021, with the aim of enhancing the equity and accessibility of health services. Using a "citizen-centric" approach, the mission will assist current health systems by utilizing IT and related technologies. The goal of ABDM is to establish a digital health ecosystem that can effectively, conveniently, inclusively, affordably, quickly, and safely provide national health coverage. It is anticipated that the mission will enhance the health service's efficacy, efficiency, and transparency. People will have the option to use both public and private health services, and medical staff will have improved access to patient medical histories in order to deliver better care.</h1>
                        </div>
                    </div>
                </div>

                {/* Health ID */}
                <div className="w-full my-[8vh]">
                    <div className="w-[80vw] mx-[10vw] py-[5vh] lg:py-[2vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0 flex flex-col justify-center items-start">
                            <h1 className="mb-[8vh] text-6xl font-bold text-left text-[#000000]">Health ID</h1>
                            <h1 className="text-lg font-regular text-left text-[#000000]">As part of the mission, people are advised to create a health ID in order to standardize the identifying procedure throughout healthcare providers. The system gathers basic information about an individual, such as contact data, family/relationship information, location, and demographics, in order to issue UHIDs (Universal Health IDs). Individuals will be uniquely identified by their Health ID, which will also serve to authenticate them and share their health records with many healthcare systems and various stakeholders—but only with their informed consent.</h1>
                            <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-5">
                                <a href='https://download.alleshealth.com/' target='_blank' className="mt-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                    Create Your ABHA  <MdOutlineArrowOutward />
                                </a>
                                <a href='https://abha.abdm.gov.in/abha/v3/' target='_blank' className="lg:mt-10 bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                    Learn More  <MdOutlineArrowOutward />
                                </a>
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/2 lg:mx-0">
                            <img src={Image1} alt="App" className="w-full h-auto object-cover" />
                        </div>
                    </div>
                </div>

                {/* WHY ABHA */}
                <div className="relative flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                    {/* Background Image */}
                    <div className="w-full flex justify-center items-center">
                        <img src={bg2} alt="background" className="w-[100vw] h-[100vh] xxxs:max-sm:h-[200vh] sm:max-lg:h-[280vh] object-cover" /></div>

                    <div className="absolute top-15 w-[80vw] mx-[10vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="w-[80vw] mx-[10vw] lg:w-3/4 lg:mx-0">
                            <h1 className="mb-[8vh] text-6xl font-bold text-left text-[#000000]">Why ABHA?</h1>
                            <h1 className="text-lg font-regular text-left text-[#000000]">Creating ABHA is the first step towards creating safer and efficient digital health records for you and your family. It provides you with:</h1>

                            <div className="flex flex-row justify-start items-center gap-10 mt-10">
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon1} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Digital Health Records</h1>
                                </div>
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon2} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Personal Health Records</h1>
                                </div>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-10 mt-10">
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon3} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Inclusive Access</h1>
                                </div>
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon4} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Voluntary Opt-Ins</h1>
                                </div>
                            </div>
                            <div className="flex flex-row justify-start items-center gap-10 mt-10">
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon5} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Secure and Private</h1>
                                </div>
                                <div className="w-1/2 flex flex-row justify-start items-center gap-2">
                                    <div className="flex justify-center items-center rounded-full">
                                        <img src={Icon6} alt="Icon" className="w-12 h-12 object-contain" />
                                    </div>
                                    <h1 className="px-1 text-md md:text-[1.2vw] font-regular text-[#000000] text-left">Consent Based Access</h1>
                                </div>
                            </div>

                            <a href='https://abha.abdm.gov.in/abha/v3/' target='_blank' className="mt-[10vh] bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                Learn More  <MdOutlineArrowOutward />
                            </a>
                        </div>
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/4 lg:mx-0">
                            <img src={Image2} alt="Why Abha" className="w-full h-auto object-cover" />
                        </div>
                    </div>
                </div>

                {/* Tile */}
                <a href='https://play.google.com/store/apps/details?id=com.alleshealth' target='_blank' className="mt-10 w-[80vw] mx-[10vw] relative">
                    <img src={Tile} alt="Tile" className="w-full h-auto object-cover" />
                    <a href='https://apps.apple.com/app/alleshealth/id6449357604' target='_blank' className="absolute bottom-0 left-0 w-[14vw] h-1/2"></a>
                </a>

                {/* How ABHA */}
                <div className="my-[10vh] flex flex-col-reverse lg:flex-row justify-center items-center gap-[5vw]">
                    <div className="w-[80vw] mx-[10vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="w-[80vw] mx-[10vw] lg:w-3/4 lg:mx-0">
                            <h1 className="mb-[5vh] text-xl lg:text-[4.4vw] font-bold text-left text-[#000000]">How to create ABHA Card?</h1>

                            <div className="flex flex-col justify-center items-start">
                                <ul className='pl-5 text-left'>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Go to AllesHealth App {'>'} Profile Settings {'>'} Create an ABHA address.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Select the option to create the ABHA address using Aadhaar for verification {'>'} Input your 12-digit Aadhaar number {'>'} Submit.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Enter the 6 digit OTP received on linked number with your Aadhar Number.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Enter the Mobile Number you wish to register for ABHA account manager {'>'} Confirm.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Create your ABHA address from suggestions provided {'>'} Confirm {'>'} AND IT{"'"}S DONE!!!</li>
                                </ul>
                            </div>

                            <a href='https://download.alleshealth.com/' target='_blank' className="mt-10 bg-[#3CA5DC] flex justify-center font-bold text-[#FFFFFF] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                Download AllesHealth  <MdOutlineArrowOutward />
                            </a>
                        </div>
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/4 lg:mx-0">
                            <img src={Image3} alt="How Abha" className="w-full h-auto object-cover" />
                        </div>
                    </div>
                </div>

                {/* Download ABHA */}
                <div className="relative my-[10vh] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                    {/* Background Image */}
                    <div className="w-full flex justify-center items-center">
                        <img src={bg3} alt="background" className="w-[100vw] h-[150vh] lg:h-[80vh] object-cover" /></div>

                    <h1 className="absolute top-[12vh] w-[80vw] mx-[10vw] mb-5 text-xl lg:text-[4.4vw] font-bold text-left text-[#000000]">Download ABHA Health Card</h1>
                    <div className="absolute top-[20vh] lg:top-[30vh] w-[80vw] mx-[10vw] flex flex-col lg:flex-row justify-center items-center gap-[5vw]">
                        <div className="w-[80vw] mx-[10vw] lg:w-3/4 lg:mx-0">

                            <div className="flex flex-col justify-center items-start">
                                <ul className='pl-5 text-left'>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>After successful completion and creation of ABHA with AllesHealth, you can go to My AllesHealth Profile.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Upon clicking on your Profile section, you will see your ABHA details along with other details you filled on the time of profile creation.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Click on ABHA section {'>'} See Details {'>'} Download Card.</li>
                                    <li style={{ listStyle: 'numbers' }} className='py-1'>Just like that you now have your ABHA card downloaded!</li>
                                </ul>
                            </div>

                            <a href='https://download.alleshealth.com/' target='_blank' className="mt-[10vh] bg-[#EEC300] flex justify-center font-bold text-[#000000] text-sm items-center w-full lg:w-[20vw] px-2 py-4 rounded-l-full rounded-r-full shadow-xl gap-2 cursor-pointer hover:scale-105 duration-500">
                                Download AllesHealth  <MdOutlineArrowOutward />
                            </a>
                        </div>
                        <div className="flex justify-center items-center w-[80vw] mx-[10vw] lg:w-1/4 lg:mx-0">
                            <img src={Image4} alt="Download Abha" className="w-full h-auto object-cover" />
                        </div>
                    </div>
                </div>
            </div>

            <hr className='mb-10 md:mb-[10vh] w-[80vw] md:w-[60vw] mx-[10vw] md:ml-20 border-black' />

            {/* FOOTER */}
            <Footer />
        </div>
    )
}

export default Abha