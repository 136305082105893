import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contact: [],
  loading: false,
};

const contactusSlice = createSlice({
  name: "contactus",
  initialState: initialState,
  reducers: {
    setContact(state, action) {
      state.contact = action.payload;
      // localStorage.setItem("contact", JSON.stringify(action.payload));
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setcontact, setLoading } = contactusSlice.actions;

export default contactusSlice.reducer;
