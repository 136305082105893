import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { setLoading } from "../../slices/contactusSlice";
import { contactEndpoints } from "../apis";

const { CONTACT_US_API } = contactEndpoints;

// ADD COMPANY USER
export function addContactUs(userData) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", CONTACT_US_API, userData, {});

      console.log("====================================");
      console.log("res", response);
      console.log("====================================");

      if (response?.error) {
        toast.error(response?.data?.message);
      }

      toast.success("Message sent!");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    dispatch(setLoading(false));
  };
}
